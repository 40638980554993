




import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import { Box, Button, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import Debug from "../components/Debug";
import useSiteMetadata from "../components/SiteMetadata";
import Markdown from "../components/Markdown";
import ButtonBackToMainPage from "../components/ButtonBackToMainPage";

const getData = ({ email, companyFullName, serviceName, companyFullNameGenitive, companyShortestName, ceo, website, address, name, nip, regon }) => (`


# Regulamin strony internetowej ${website}

Strona internetowa ${website} jest własnością ${companyFullNameGenitive}.

Kontakt do nas: ${email}

## I. Postanowienia ogólne.

1. Poniżej znajdziesz regulamin, w którym opisane są informacje m.in. o sposobie, w jaki udostępniamy nieodpłatnie treści na stronie ${website} oraz świadczymy usługę dostępną drogą elektroniczną w postaci Formularza kontaktowego.

2. Regulamin skierowany jest zarówno do konsumentów, jak i do przedsiębiorców korzystających ze Sklepu Internetowego, chyba że dane postanowienie Regulaminu stanowi inaczej i jest skierowane wyłącznie do konsumentów albo do przedsiębiorców.

## II. Definicje na potrzeby regulaminu, przyjmuje się następujące znaczenie poniższych pojęć:

1. **Strona internetowa/witryna internetowa** – strona internetowa Usługodawcy dostępna pod adresem internetowym: ${website}

2. **Usługodawca** – ${companyFullName}.

3. **Usługobiorca** - osoba fizyczna, osoba prawna albo jednostka organizacyjna nieposiadająca osobowości prawnej, która korzysta z usługi świadczonej drogą elektroniczną;

4. **Usługa elektroniczna** – usługa świadczona drogą elektroniczną przez Usługodawcę na rzecz Usługobiorcy za pośrednictwem witryny internetowej.

5. **Formularz Kontaktowy** – usługa elektroniczna, interaktywny formularz dostępny na stronie internetowej, umożliwiający przesłanie wiadomości jeżeli chcesz się ze nami skontaktować.

## III. Treści dostępne nieodpłatnie.

1. Na stronie internetowej publicznie dostępne są treści tekstowe, graficzne, dźwiękowe i audiowizualne, z którymi możesz zapoznawać się nieodpłatnie i anonimowo, tj. bez konieczności podawania swoich danych osobowych.

2. Przeglądanie publicznie dostępnej zawartości strony wymaga posiadania dostępu do internetu i urządzenia wraz z oprogramowaniem pozwalającego na przeglądanie zawartości internetu.

## IV. Prawa autorskie.

Prawa autorskie do treści zawartych na stronie internetowej (treści tekstowych, fotografii, grafik, utworów audio i video) przysługują nam lub podmiotom z nami współpracującym. Korzystanie z tych treści z naruszeniem zasad niniejszego regulaminu lub prawa autorskiego może skutkować odpowiedzialnością cywilną lub karną.


## V. Usługi elektroniczne w na stronie internetowej ${website}

1. Na stronie internetowej dostępna jest usługa elektroniczna w formie Formularza kontaktowego.

2. Usługa Formularza kontaktowego – świadczenie usługi Formularza inicjowane jest przez Ciebie poprzez użycie widocznego na stronie internetowej okna Formularza kontaktowego. Usługa polega na umożliwieniu wpisania do Formularza Twojego imienia i nazwiska, adresu e-mail oraz wiadomości jaką chcesz nam przekazać, a następnie kliknięcie pola „Wyślij”. Na Twoje pytanie odpowiemy na podany przez Ciebie adres e-mail.


## VI. Tryb postępowania reklamacyjnego dotyczący Usług Elektronicznych

1. Reklamacje związane ze świadczeniem usług elektronicznych przez Usługodawcę oraz pozostałe reklamacje związanie z działaniem witryny internetowej, Usługobiorca może składać:
a. pisemnie na adres: ${address}
b. w formie elektronicznej za pośrednictwem poczty elektronicznej na adres: ${email}
2. Zaleca się podanie przez Usługobiorcę w opisie reklamacji: (1) informacji i okoliczności dotyczących przedmiotu reklamacji, w szczególności rodzaju i daty wystąpienia nieprawidłowości; (2) żądania Usługobiorcy; oraz (3) danych kontaktowych składającego reklamację – ułatwi to i przyspieszy rozpatrzenie reklamacji przez Usługodawcę.

Wymogi podane w zdaniu poprzednim mają formę jedynie zalecenia i nie wpływają na skuteczność reklamacji złożonych z pominięciem zalecanego opisu reklamacji.

3. Ustosunkowanie się do reklamacji przez Usługodawcę następuje niezwłocznie, nie później niż w terminie 14 dni kalendarzowych od dnia jej złożenia.

## VII. Dane osobowe i pliki cookies.

1. Jeżeli w Formularzu kontaktowym podajesz nam swoje dane osobowe, jesteśmy ich Administratorem w rozumieniu przepisów o ochronie danych osobowych.
2. Witryna internetowa wykorzystuje technologię plików cookies.
3. Szczegóły związane z przetwarzaniem danych osobowych oraz plikami cookies opisane są w Polityce prywatności i plików cookies mieszczącej się pod adresem ${website}/polityka-prywatnosci

## VIII. Zmiany regulaminu.

1. Możemy modyfikować ten regulamin z ważnych powodów, takich jak zmiany prawne, technologiczne lub biznesowe. Do umów zawartych przed zmianą regulaminu stosuje się regulamin w brzmieniu obowiązującym w chwili zawarcia umowy.
2. Regulamin w obecnej wersji obowiązuje od dnia 16 październik 2024 r.

         `)


export const IndexPage = ({

}) => {


    const siteMeta = useSiteMetadata();


    return (
        <Layout variant="regulamin">

            <Container maxWidth="sm" sx={{

                position: "relative"
            }}>


                <ButtonBackToMainPage />

                {/* <Typography variant="h1">Polityka prywatności</Typography> */}

                <Markdown>{getData(siteMeta)}</Markdown>



            </Container>
        </Layout>

    );
};




export default IndexPage;

export const Head = () => {

    const { tel,
        linkgooglemap,
        address,
        title,
        description,
        siteUrl
    } = useSiteMetadata();

    return (<>
        {/* https://www.searchenginejournal.com/important-tags-seo/156440/ */}
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* <meta name="robots" content="nofollow"> */}

        {/* <link rel="canonical" href={siteUrl} /> */}

        <meta property="og:title" content={title} />

        <meta property="og:description" content={description} />

        <meta property="og:image" content={`${siteUrl}/img/map.jpg`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* <meta charset="UTF-8" /> */}


    </>


    )
}


